import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback, useState } from 'react'
import { colors } from '@/styles/theme'
import { BlueButton } from '@/components/atoms/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faChalkboardTeacher,
  faComments,
  faCompass,
  faIdCard,
  faNewspaper,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'
import { Users } from '@/firebase/firestore/Users'
import { useRouter } from 'next/navigation'
import { useLocale } from '@/hooks/useLocale'
import { logClickEvent } from '@/clients/firebase/analytics'
import { logout } from '@/clients/firebase/helpers'

type Props = {
  currentUser: null | Users
  hasUnreadNotifications: boolean
}

export const NavPC: React.FC<Props> = ({
  currentUser,
  hasUnreadNotifications,
}) => {
  const router = useRouter()
  const { t } = useLocale()
  const [isPopupVisible, setPopupVisible] = useState(false)

  const onClickLogout = useCallback(() => {
    setPopupVisible(false)
    logClickEvent('HeaderLogoutClick')
    logout()
    router.push('/')
  }, [router])
  const onClickLogin = useCallback(() => {
    setPopupVisible(false)
    logClickEvent('HeaderLoginClick')
    router.push('/loginOrSignup?redirectTo=/me')
  }, [router])

  function togglePopup(event) {
    event.preventDefault()
    setPopupVisible(!isPopupVisible)
  }

  function PopupMenu() {
    return (
      <PopupMenuContainer>
        <PopupMenuItem onClick={onClickLogin}>{t.マイページ}</PopupMenuItem>
        <PopupMenuItem onClick={onClickLogout}>{t.ログアウト}</PopupMenuItem>
      </PopupMenuContainer>
    )
  }
  return (
    <Container>
      <Left>
        <Link
          href={'/'}
          style={{
            textDecoration: 'none',
          }}
        >
          <Logo>
            <Image
              src="/static/images/icn_gamee_logo.png"
              width={135}
              height={39}
              alt={'gamee'}
              priority={true}
              unoptimized={true}
            />
          </Logo>
        </Link>
        <NavMenuList>
          <NavMenuItem>
            <Link
              href={'/me/profiles/create'}
              style={{
                textDecoration: 'none',
              }}
            >
              <NavMenuItemInner>
                <Icon>
                  <FontAwesomeIcon
                    icon={faIdCard}
                    size="2x"
                    color={colors.text.primary}
                  />
                </Icon>
                <NavMenuText>{t.自己紹介カード}</NavMenuText>
              </NavMenuItemInner>
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link
              href={'/bosyus'}
              style={{
                textDecoration: 'none',
              }}
            >
              <NavMenuItemInner>
                <Icon>
                  <FontAwesomeIcon
                    icon={faCompass}
                    size="2x"
                    color={colors.text.primary}
                  />
                </Icon>
                <NavMenuText>{t.ゲーム友達募集}</NavMenuText>
              </NavMenuItemInner>
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link
              href={'/groupRooms/apex'}
              style={{
                textDecoration: 'none',
              }}
            >
              <NavMenuItemInner>
                <Icon>
                  <FontAwesomeIcon
                    icon={faPeopleGroup}
                    size="1x"
                    color={colors.text.primary}
                  />
                </Icon>
                <NavMenuText>{t.グループ一覧}</NavMenuText>
              </NavMenuItemInner>
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link
              href={'/shindan'}
              style={{
                textDecoration: 'none',
              }}
            >
              <NavMenuItemInner>
                <Icon>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    size="1x"
                    color={colors.text.primary}
                  />
                </Icon>
                <NavMenuText>{t.性格診断}</NavMenuText>
              </NavMenuItemInner>
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link
              href={'https://blog.gamee.games'}
              style={{
                textDecoration: 'none',
              }}
            >
              <NavMenuItemInner>
                <Icon>
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    size="1x"
                    color={colors.text.primary}
                  />
                </Icon>
                <NavMenuText>Gamee Blog（ゲーミーブログ）</NavMenuText>
              </NavMenuItemInner>
            </Link>
          </NavMenuItem>
        </NavMenuList>
      </Left>
      <Right>
        {currentUser && (
          <Link
            href="/announces"
            style={{
              textDecoration: 'none',
            }}
          >
            <NavMenuItemInner>
              <Icon>
                <FontAwesomeIcon
                  icon={faBell}
                  size="1x"
                  color={colors.text.primary}
                />
              </Icon>
              <NavMenuText>お知らせ</NavMenuText>
              {hasUnreadNotifications && <Badge />}
            </NavMenuItemInner>
          </Link>
        )}
        {currentUser == null ? (
          <MyPageButton onClick={onClickLogin}>
            <span>{t.ログイン}</span>
          </MyPageButton>
        ) : (
          <>
            <MyPageButton onClick={togglePopup}>
              {currentUser?.profileImgUrl && (
                <MyPageButtonIcon>
                  <Image
                    src={currentUser?.profileImgUrl}
                    width={32}
                    height={32}
                    alt={`${currentUser?.name}`}
                    priority={true}
                    unoptimized={true}
                  />
                </MyPageButtonIcon>
              )}
              <span>マイページ</span>
            </MyPageButton>
            {isPopupVisible && <PopupMenu />}
          </>
        )}
      </Right>
    </Container>
  )
}

const Container = styled.nav`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.div`
  margin-bottom: -12px;
  width: 135px;
  heght: 59px;
  overflow: hidden;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const NavMenuList = styled.div`
  display: flex;
  margin-left: 32px;
  justify-content: center;
  align-items: center;
`

const NavMenuItem = styled.div`
  margin-left: 24px;
`

const NavMenuItemInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavMenuText = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: ${colors.text.primary};
  text-decoration: none;
  margin-left: 8px;
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const MyPageButton = styled(BlueButton)`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  background-color: #80b8d7;
  border-radius: 60px;
  margin-left: 24px;
`

const MyPageButtonIcon = styled.div`
  height: 32px;
  width: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
`

const Badge = styled.span`
  position: absolute;
  top: -8px;
  left: -8px;
  background-color: #ff4d4f;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PopupMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const PopupMenuItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  color: #4c4c4c;
  &:hover {
    background-color: #f5f5f5;
  }
`
